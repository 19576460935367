import React, {
    createRef,
    Dispatch,
    Fragment, Reducer,
    SetStateAction,
    useCallback,
    useEffect,
    useReducer,
    useState
} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar'
import TypoGraphy from '@material-ui/core/Typography'
import Typography from '@material-ui/core/Typography'
import {CardActions, Container, CssBaseline, Link, makeStyles} from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

import {useDropzone} from "react-dropzone";
import {AttachFile} from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";

const SERVER_URI = "https://v4.eu.api.visualsearch.ingka.dev/api/images";

const retailUnitsAvailable: Array<{ ru: string, name: string, hasImg: boolean }> = [
    {ru: 'ALL', name: "All countries", hasImg: true},
    {ru: 'US', name: "Unites States", hasImg: true},
    {ru: 'SE', name: "Sweden", hasImg: true},
    {ru: 'NO', name: "Norway", hasImg: true},
    {ru: 'FI', name: "Finland", hasImg: true},
    {ru: 'FR', name: "France", hasImg: true},
    {ru: 'GB', name: "Great Britain", hasImg: true},
    {ru: 'CA', name: "Canada", hasImg: true},
    {ru: 'DK', name: "Denmark", hasImg: true},
    {ru: 'AT', name: 'Austria', hasImg: true},
    {ru: 'BE', name: 'Belgium', hasImg: true},
    {ru: 'DE', name: 'Germany', hasImg: true},
    {ru: 'ES', name: 'Spain', hasImg: true},
    {ru: 'IE', name: 'Ireland', hasImg: true},
    {ru: 'NL', name: 'Netherlands', hasImg: true},
    {ru: 'PT', name: 'Portugal', hasImg: true},
    {ru: 'RO', name: 'Romania', hasImg: true},
];
const useStyles = makeStyles({
    root: {
        color: 'white'
    },
});

interface DropzoneProps {
    callback: (files: Array<File>) => void
}

/**
 * Stores a string value in localStorage.
 * @param localStorageKey
 * @param defaultValue
 */
const useStateWithLocalStorage = (localStorageKey: string, defaultValue: string): [string, Dispatch<SetStateAction<string>>] => {
    const [value, setValue] = React.useState(
        localStorage.getItem(localStorageKey) || defaultValue
    );

    React.useEffect(() => {
        localStorage.setItem(localStorageKey, value);
    }, [localStorageKey, value]);

    return [value, setValue];
};

function Dropzone(props: DropzoneProps) {
    const onDrop = useCallback((acceptedFiles: Array<File>) => {
        props.callback(acceptedFiles);
    }, [props]);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    return (
        <div {...getRootProps()} style={{border: "solid 1px lightgray"}}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p><AttachFile/>Drop the file here ...</p> :
                    <p><AttachFile/>Drag 'n' drop an image here, or click to select</p>
            }
        </div>
    )
}

export interface Image {
    alt?: string;
    id: string;
    imageFileName?: string;
    url?: string;
}

export interface ProductResultItem {
    itemNo: string;
    score: number;
    name?: string;
    mainImage?: Image,
    itemType: string,
    market?: string,
    pipUrl?: string,
    typeName?: string
}


interface BoundingBox {
    x: number
    y: number
    width: number
    height: number
}

interface ProcessedResult {
    bbox: BoundingBox
    results: Array<ProductResultItem>
}

interface SearchResponse {
    results?: Array<ProductResultItem>
    groupedResults: [
        {
            verts: Array<{
                x: number
                y: number
            }>
            results: Array<ProductResultItem>
        }
    ]
}

interface ImageProcessorProps {
    file: File
    selectNewImageFn: () => void
    retailUnit: string
    relevance: number
}

enum ProcessState {
    NOT_STARTED,
    PROCESSING,
    PROCESSED
}


interface OngoingQuery {
    id: number
    bbox: BoundingBox
}

function BoxDrawingArea(props: { addBboxFn: (bbox: BoundingBox) => void }) {
    const [currentBbox, setCurrentBbox] = useState<BoundingBox>();
    const [startPos, setStartPos] = useState<{ x: number, y: number }>();

    let currentDrawnElement = null;

    if (currentBbox) {
        currentDrawnElement = <div style={{
            position: 'absolute',
            borderColor: '#0058A3',
            borderStyle: 'solid',
            borderWidth: '1.5px',
            left: `${currentBbox.x * 100}%`,
            top: `${currentBbox.y * 100}%`,
            width: `${currentBbox.width * 100}%`,
            height: `${currentBbox.height * 100}%`,
            zIndex: 20,
            borderRadius: "5px"
        }}/>;
    }

    const ref = createRef<HTMLDivElement>();

    return <Tooltip title={"Mark and drag with your mouse to search for items."}>
        <div ref={ref}
             style={
                 {
                     maxWidth: '100%',
                     position: 'absolute',
                     zIndex: startPos ? 15 : 2,
                     width: '100%',
                     height: '100%'
                 }
             }
             onMouseDown={e => {
                 if (ref.current) {
                     const rect = ref.current.getBoundingClientRect();
                     const relX = (e.clientX - rect.left);
                     const relY = (e.clientY - rect.top);

                     setStartPos({x: relX, y: relY});
                 }
             }
             }
             onMouseMove={e => {
                 if (startPos && ref.current) {
                     const bbox: BoundingBox = {x: 0, y: 0, width: 0, height: 0};
                     const rect = ref.current.getBoundingClientRect();
                     const width = ref.current.clientWidth;
                     const height = ref.current.clientHeight;

                     const relX = (e.clientX - rect.left);
                     const relY = (e.clientY - rect.top);
                     if (relX < startPos.x) {
                         bbox.x = relX / width;
                         bbox.width = (startPos.x - relX) / width;
                     } else {
                         bbox.x = startPos.x / width;
                         bbox.width = (relX - startPos.x) / width;
                     }
                     if (relY < startPos.y) {
                         bbox.y = relY / height;
                         bbox.height = (startPos.y - relY) / height;
                     } else {
                         bbox.y = startPos.y / height;
                         bbox.height = (relY - startPos.y) / height;
                     }
                     setCurrentBbox({...bbox});
                 }
             }}
             onMouseUp={() => {
                 setStartPos(undefined);
                 if (currentBbox) {
                     if (currentBbox.width > 0 && currentBbox.height > 0) {
                         props.addBboxFn(currentBbox);
                     }
                     setCurrentBbox(undefined);
                     return true;
                 }
                 return false;
             }}
        >
            {currentDrawnElement}
        </div>
    </Tooltip>;
}

interface HttpErrors {
    time: number
    errorCode: number
}

interface ListReducerAction<T> {
    type: "add" | "remove" | "set";
    item: T | Array<T>
}

function createListReducer<T>(): Reducer<Array<T>, ListReducerAction<T>> {
    return function <T>(state: Array<T>, action: ListReducerAction<T>): Array<T> {
        switch (action.type) {
            case "add":
                return [...state, action.item as T];
            case "remove":
                return state.filter(it => it !== action.item as T);
            case "set":
                return [...action.item as Array<T>];
        }
    }
}


function ImageProcessor(props: ImageProcessorProps) {

    const [processState, setProcessing] = useState(ProcessState.NOT_STARTED);
    const [productResults, dispatchProductResults] = useReducer(createListReducer<ProcessedResult>(), []);
    const [selectedResult, setSelectedResult] = useState<ProcessedResult>();
    const [ongoingQueries, dispatchOngoingQueries] = useReducer(createListReducer<OngoingQuery>(), []);
    const [httpErrors, setHttpErrors] = useState<Array<HttpErrors>>([]);

    const scrollRef = createRef<HTMLDivElement>();
    React.useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTo(0, 0);
        }
// eslint-disable-next-line
    }, [selectedResult]);

    //If there's only one result, select that.
    useEffect(() => {
        if (productResults.length === 1 && !selectedResult) {
            setSelectedResult(productResults[0]);
        }
    }, [productResults, selectedResult]);


    const cropImage = (imgObj: HTMLImageElement, bbox: BoundingBox) => {

        let dstWidth = bbox.width * imgObj.naturalWidth;
        let dstHeight = bbox.height * imgObj.naturalHeight;
        const srcWidth = dstWidth;
        const srcHeight = dstHeight;

        //Max 300px in width; backend can't handle larger.
        if (dstWidth > 300) {
            dstHeight = (300 / dstWidth) * dstHeight;
            dstWidth = 300;
        }


        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');
        if (canvasContext) {
            canvas.width = dstWidth;
            canvas.height = dstHeight;

            //Make a complete copy of the image first
            const bufferCanvas = document.createElement('canvas');
            const bufferContext = bufferCanvas.getContext('2d');
            if (bufferContext) {
                bufferCanvas.width = imgObj.naturalWidth;
                bufferCanvas.height = imgObj.naturalHeight;
                bufferContext.drawImage(imgObj, 0, 0);

                canvasContext.drawImage(bufferCanvas,
                    bbox.x * imgObj.naturalWidth,
                    bbox.y * imgObj.naturalHeight,
                    srcWidth,
                    srcHeight,
                    0,
                    0,
                    canvas.width,
                    canvas.height);
            }
        }
        return new Promise<Blob>((resolve, reject) => {
                canvas.toBlob((blob) => {
                    if (blob) {
                        resolve(blob);
                    } else {
                        reject();
                    }
                }, "image/jpeg");
            }
        );
    };

    const buildFetchOptions = (content: string, retailUnit: string, relevance: number) => {
        return {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-IVS-APIKey': 'W4imE9TT7neG0wD1aEQb' //This is a key specific to this app. Note that keys aren't
                // secret per se; they just allow us to have some control over who's accessing the backend.
            },
            body: JSON.stringify({
                RU: retailUnit,
                relevance: relevance,
                content: content,
                uniquePe: true
            })
        }
    };

    if (processState === ProcessState.NOT_STARTED) {
        const reader = new FileReader();
        reader.onload = async () => {
            const result = reader.result;
            const encoded = btoa(result as string);

            try {
                const response = await fetch(SERVER_URI, buildFetchOptions(encoded, props.retailUnit, props.relevance));
                if (response.status >= 400) {
                    setHttpErrors([...httpErrors, {errorCode: response.status, time: new Date().getDate()}]);
                    setProcessing(ProcessState.PROCESSED);
                } else {
                    const json = await response.json() as SearchResponse;
                    setProcessing(ProcessState.PROCESSED);

                    const processed = json.groupedResults.map(groupedResult => {
                        //Create a bounding box
                        const verts = groupedResult.verts;
                        const bbox: BoundingBox = {
                            x: verts[0].x,
                            y: verts[0].y,
                            width: verts[2].x - verts[0].x,
                            height: verts[2].y - verts[0].y
                        };
                        return {bbox: bbox, results: groupedResult.results};
                    });

                    dispatchProductResults({type: 'set', item: processed});
                }
            } catch (e) {
                console.log(JSON.stringify(e));
                console.error("Error when annotating image.", e);
//                setHttpErrors(httpErrors.push({errorCode: e.stat}))
                setProcessing(ProcessState.PROCESSED)
            }

        };
        reader.readAsBinaryString(props.file);
        setProcessing(ProcessState.PROCESSING);


    }

    let selectedResultElement = null;

    if (selectedResult) {
        selectedResultElement =
            <Fragment>
                <Typography variant={"h4"}>
                    Showing {selectedResult.results.length} entries
                </Typography>
                <Button variant="contained" onClick={() => {
                    dispatchProductResults({type: "remove", item: selectedResult});
                    setSelectedResult(undefined);
                }} style={{marginBottom: 12, marginTop: 12}}>Delete query</Button>

                <div style={{
                    overflow: 'auto',
                    maxHeight: '100vh'
                }} ref={scrollRef}>
                    {selectedResult.results.map(result => {
                        return <Card style={{marginBottom: 12}} key={result.itemNo}>
                            <CardContent>
                                <img style={{maxWidth: '150px', float: 'left', marginRight: "16px"}}
                                     src={result.mainImage ? result.mainImage.url : ''} alt={result.name}/>
                                <Typography variant={"h6"}>
                                    {result.name}
                                </Typography>
                                {result.typeName}<br/>
                                {result.itemType}-{result.itemNo}<br/>
                            </CardContent>
                            <CardActions>
                                <a target="_blank" href={result.pipUrl} rel='noopener noreferrer'>
                                    <Button color="primary">
                                        Show on IKEA.com
                                    </Button>
                                </a>
                            </CardActions>
                        </Card>;

                    })}
                </div>
            </Fragment>
    }

    const imageRef = createRef<HTMLImageElement>();

    const addBboxFn = async (bbox: BoundingBox) => {
        if (imageRef.current) {
            console.log('Performing request for cropped image.');
            const query: OngoingQuery = {bbox: bbox, id: new Date().valueOf()};
            dispatchOngoingQueries({type: "add", item: query});
            const blob = await cropImage(imageRef.current, bbox);

            const reader = new FileReader();
            reader.onload = async () => {
                const result = reader.result;
                const encoded = btoa(result as string);

                const response = await fetch(SERVER_URI, buildFetchOptions(encoded, props.retailUnit, props.relevance));
                //Remove once the query has completed
                dispatchOngoingQueries({type: "remove", item: query});

                if (response.status >= 400) {
                    setHttpErrors([...httpErrors, {errorCode: response.status, time: new Date().getDate()}]);
                } else {
                    const json = await response.json() as SearchResponse;

                    if (json.results) {
                        //Inject into grouped results
                        const processedResult: ProcessedResult = {results: json.results, bbox: bbox};

                        dispatchProductResults({type: "add", item: processedResult});
                        //Select the new result.
                        setSelectedResult(processedResult);
                    }
                }

            };
            reader.readAsBinaryString(blob);
        }
    };


    return <Grid container spacing={3}>
        <Grid item xs={5}>
            <div>
                {httpErrors.map((httpError) => <Snackbar
                    key={httpError.time}
                    open={true}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    autoHideDuration={5000}
                    onClose={() => setHttpErrors(httpErrors.filter(error => error !== httpError))}
                    message={`Request failed with code ${httpError.errorCode}`}/>)}
                <div style={{position: "relative", overflow: "hidden"}}>
                    {processState !== ProcessState.PROCESSED && <div style={{
                        position: 'absolute',
                        zIndex: 10,
                        backgroundColor: "white",
                        display: "flex",
                        opacity: 0.6,
                        width: '100%',
                        height: '100%',
                        justifyContent: "center",
                        alignItems: 'center'
                    }}>
                        <CircularProgress/>
                        <div>Processing...</div>
                    </div>}
                    {processState === ProcessState.PROCESSED &&
                    <div style={{
                        maxWidth: '100%',
                        position: 'absolute',
                        zIndex: 10,
                        width: '100%',
                        height: '100%'
                    }}>

                        {productResults.map(productResult => {

                            let selected = false;
                            if (selectedResult && selectedResult === productResult) {
                                selected = true;
                            }

                            const bbox = productResult.bbox;
                            const key = `${productResult.bbox.x}-${productResult.bbox.y}-${productResult.bbox.width}-${productResult.bbox.height}`;
                            return <Tooltip title={`${productResult.results.length} products matching.`} key={key}>
                                <div style={{
                                    position: 'absolute',
                                    borderColor: selected ? '#FFDB00' : '#0058A3',
                                    borderStyle: 'solid',
                                    borderWidth: "1.5px",
                                    left: `${bbox.x * 100}%`,
                                    top: `${bbox.y * 100}%`,
                                    width: `${bbox.width * 100}%`,
                                    height: `${bbox.height * 100}%`,
                                    zIndex: selected ? 5 : 10, //Put selected box behind other ones, so it's possible to select a box obscured by another by selecting twice.
                                    borderRadius: "5px"
                                }} onClick={() => setSelectedResult(productResult)}>
                                </div>
                            </Tooltip>
                        })}

                        {ongoingQueries.map(query => {
                            const bbox = query.bbox;
                            return <Tooltip title={`Ongoing query.`} key={query.id}>
                                <div style={{
                                    position: 'absolute',
                                    borderColor: 'lightgrey',
                                    borderStyle: 'solid',
                                    borderWidth: '1px',
                                    opacity: 0.2,
                                    backgroundColor: "white",
                                    left: `${bbox.x * 100}%`,
                                    top: `${bbox.y * 100}%`,
                                    width: `${bbox.width * 100}%`,
                                    height: `${bbox.height * 100}%`,
                                    zIndex: 2,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: 'center'
                                }}>
                                    <CircularProgress/>
                                </div>
                            </Tooltip>
                        })}
                        <BoxDrawingArea addBboxFn={addBboxFn}/>
                    </div>}


                    <img style={{maxWidth: '100%', width: '100%'}} src={URL.createObjectURL(props.file)} alt=""
                         ref={imageRef}/>
                </div>
            </div>
        </Grid>
        <Grid item xs={7}>
            <Button variant="contained" color="primary" style={{margin: 8}}
                    onClick={() => setProcessing(ProcessState.NOT_STARTED)}>Annotate whole image</Button>
            <Button variant="contained" style={{margin: 8}} onClick={props.selectNewImageFn}>Select new
                image</Button>
            <Link target="_blank" rel="noopener" style={{margin: 8}} href='mailto:the.cyberlab.se@ikea.com'>Give us
                feedback</Link>
            {selectedResultElement}
        </Grid>
    </Grid>;


}


async function createFile(): Promise<File> {
    let response = await fetch('/test_small.jpg');
    let data = await response.blob();
    let metadata = {
        type: 'image/jpeg'
    };
    return new File([data], "test_small.jpg", metadata);
}

function App() {

    const classes = useStyles();
    const [file, setFile] = useState<File>();

    const [retailUnit, setRetailUnit] = useStateWithLocalStorage('RETAIL_UNIT', 'SE');

    const handleFileChange = (files: Array<File>) => {
        if (files.length > 0) {
            setFile(files[0]);
        } else {
            setFile(undefined);
        }
    };

    const useTestFile = async () => {
        const file = await createFile();
        setFile(file);
    };

    return (

        <React.Fragment>
            <CssBaseline/>
            <div>
                <AppBar color="primary" position="static">
                    <Toolbar>
                        <TypoGraphy variant="h4" color="inherit">
                            Visual Search
                        </TypoGraphy>

                        <InputLabel className={classes.root} style={{marginLeft: "16px"}}
                                    htmlFor="ru">Market&nbsp;</InputLabel>
                        <Select
                            className={classes.root}
                            value={retailUnit}
                            onChange={e => setRetailUnit(e.target.value as string)}
                            inputProps={{
                                name: 'ru',
                                id: 'ru',
                            }}
                        >
                            {retailUnitsAvailable.map(it => <MenuItem key={it.ru}
                                                                      value={it.ru} style={{textAlign: 'center'}}>
                                <img src={`flags/${it.ru}.png`} alt={it.name}/> {it.name}</MenuItem>)}
                        </Select>
                    </Toolbar>
                </AppBar>

                <div style={{marginTop: 20, padding: 30}}>
                    {file ?
                        <ImageProcessor file={file}
                                        selectNewImageFn={() => setFile(undefined)}
                                        relevance={0.2}
                                        retailUnit={retailUnit}/>
                        :
                        <Container>
                            <p>This tools allows you to upload an image and run visual product detection on it.
                                The system will try to
                                find products matching IKEA's range.</p>
                            <Dropzone callback={handleFileChange}/>
                            {(window.location.hostname === 'localhost') && <Fragment>
                                <p>
                                    or
                                </p>
                                <p>
                                    <Button variant="contained" color="primary" onClick={useTestFile}>
                                        Use test image
                                    </Button>
                                </p>
                            </Fragment>}
                        </Container>
                    }
                </div>
            </div>
            <Container>Copyright© 2019 Ingka Group</Container>
        </React.Fragment>
    );

}

export default App;
